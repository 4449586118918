import { gsap } from "gsap";

const BASE_URL = "https://rolemodel.bendingspoons.com";
const EVENT_ID = "60f808c9d8e65c394517f7f2";
const ROLE_ID = "60f80703d8e65c394517f7f1";
const MONTH_NAMES = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const handleErrors = (response) => {
  if (!response.ok) throw new Error(response.status);
  return response;
};

const fetchDeadline = async (testingDate) => {
  const deadlineText = document.querySelectorAll(".deadline-text");
  const todayDate = testingDate ? new Date(testingDate) : new Date();
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const testing = urlParams.get("testing");
  const applicationButton = document.querySelectorAll(".button-apply");
  const applicationButtonText = document.querySelector(".button-apply__text");
  let applicationYetToStart = false;
  let applicationOver = false;
  let applicationOpen = false;

  let shouldShowReminder = await fetch(`${BASE_URL}/events/${EVENT_ID}`, {
    method: "GET",
  })
    .then(handleErrors)
    .then((response) => response.json())
    .then((data) => {
      const applicationDeadline = new Date(data.close_at);
      const applicationOpening = new Date(data.open_at);
      applicationYetToStart = applicationOpening > todayDate;
      applicationOver = applicationDeadline < todayDate;
      applicationOpen = data.are_applications_open;
      if (!applicationOpen && !testing && applicationYetToStart) {
        applicationButton.forEach((el) => {
          el.querySelector(".button-apply__text").innerHTML =
            "Coming<br />Soon";
          el.classList.add("disabled");
          el.disabled = true;
        });
        deadlineText.forEach((el) => {
          el.textContent = `Applications open ${
            MONTH_NAMES[applicationOpening.getUTCMonth()]
          } ${applicationOpening.getUTCDate()}, ${applicationOpening.getUTCFullYear()}`;
        });
        return true;
      }
      if (applicationOpen || testing) {
        deadlineText.forEach((el) => {
          el.textContent = `Apply by ${
            MONTH_NAMES[applicationDeadline.getUTCMonth()]
          } ${applicationDeadline.getUTCDate()}, ${applicationDeadline.getUTCFullYear()}`;
        });
        return false;
      }
      if (!applicationOpen && !testing && applicationOver) {
        applicationButton.forEach((el) => {
          el.querySelector(".button-apply__text").innerHTML = "Closed";
          el.classList.add("disabled");
          el.disabled = true;
        });
        deadlineText.forEach((el) => {
          el.textContent = "This edition is over. See you next year!";
        });
        return false;
      }
    })
    .catch((error) => {
      console.log("Error:", error);
      return false;
    });
  return shouldShowReminder;
};

export const handleApplication = async () => {
  const shouldShowReminder = await fetchDeadline();
  const applicationContent = document.getElementById("application_content");
  const applicationInner = document.getElementById("application_inner");
  const successCloseButton = document.querySelector(
    ".modal__apply-button__success"
  );
  const successInner = document.getElementById("success_inner");
  const applyMain = document.getElementById("apply_button");
  const closeButton = document.querySelector(".modal__close");

  const validateEmail = (email) => {
    var re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const validateInput = (firstname, lastname, email) => {
    if (firstname.replace(/\s/g, "").length === 0) {
      return "Please specify a valid first name.";
    }
    if (lastname.replace(/\s/g, "").length === 0) {
      return "Please specify a valid last name.";
    }
    if (email == "" || !validateEmail(email)) {
      return "Please specify a valid email.";
    }

    return false;
  };

  const allFilled = () => {
    let filled = false;

    if (
      document.getElementById("privacy").checked &&
      document.getElementById("european").checked
    ) {
      filled = true;
    }
    applicationContent.querySelectorAll("input.required").forEach((inputEl) => {
      if (!inputEl.value) filled = false;
    });
    applicationContent
      .querySelectorAll("input.required, .input-file")
      .forEach((inputEl) => {
        if (!inputEl.value) filled = false;
      });
    return filled;
  };

  const validateForm = () => {
    if (allFilled()) {
      applyMain.disabled = false;
      applyMain.classList.remove("disabled");
    } else {
      applyMain.disabled = true;
      applyMain.classList.add("disabled");
    }
  };

  const saveTracking = () => {
    // calculate the query string
    var qs = (function (a) {
      if (a == "") return {};
      var b = {};
      for (var i = 0; i < a.length; ++i) {
        var p = a[i].split("=", 2);
        if (p.length == 1) b[p[0]] = "";
        else b[p[0]] = decodeURIComponent(p[1].replace(/\+/g, " "));
      }
      return b;
    })(window.location.search.substr(1).split("&"));

    if (qs && window.localStorage) {
      var utm_source =
        qs.utm_source || localStorage.getItem("utm_source") || "";
      var utm_medium =
        qs.utm_medium || localStorage.getItem("utm_medium") || "";
      var utm_term = qs.utm_term || localStorage.getItem("utm_term") || "";
      var utm_content =
        qs.utm_content || localStorage.getItem("utm_content") || "";
      var utm_campaign =
        qs.utm_campaign || localStorage.getItem("utm_campaign") || "";

      localStorage.setItem("utm_source", utm_source);
      localStorage.setItem("utm_medium", utm_medium);
      localStorage.setItem("utm_term", utm_term);
      localStorage.setItem("utm_content", utm_content);
      localStorage.setItem("utm_campaign", utm_campaign);
    }
  };

  const successForm = () => {
    const tl = gsap.timeline({});
    tl.to(applicationInner.children, {
      autoAlpha: 0,
      duration: 0.4,
      ease: "power2.out",
      stagger: 0.08,
      onComplete: () => {
        applicationInner.classList.add("hidden");
        successInner.classList.remove("hidden");
      },
    });
    tl.from(successInner.children, {
      autoAlpha: 0,
      duration: 0.6,
      ease: "power2.in",
      stagger: 0.1,
    });
  };

  const errorForm = (errors, modal) => {
    const errorMessage = modal.querySelector(".error-text");
    const errorMessageText = modal.querySelector(".error-text p");
    if (errors) {
      errorMessage.classList.add("flex");
      errorMessageText.textContent = errors
        ? errors
        : "An error has occured, please try again.";
    }
  };
  const uploadHelper = () => {
    const items = [...document.querySelectorAll(".input-file")];

    items.forEach((el) => {
      el.addEventListener("change", (el) => {
        const parentWrapper = el.target.parentNode;
        const button = parentWrapper.querySelector(".upload-wrapper__button");
        const label = parentWrapper.querySelector(
          ".label-wrapper .upload-label"
        );
        const labelInner = parentWrapper.querySelector(
          ".label-wrapper .upload-label .upload-label__inner"
        );
        let fileName = "";
        if (el.target.files[0]) {
          const fileSize = el.target.files[0].size / 1024 / 1024;
          fileName = el.target.value.split("\\").pop();
          const fileExt =
            fileName.substring(
              fileName.lastIndexOf(".") + 1,
              fileName.length
            ) || fileName;
          if (fileSize > 2) {
            alert("File size exceeds 2 MB");
            el.target.value = "";
          } else if (fileExt != "pdf") {
            alert("Please use a PDF file");
            el.target.value = "";
          } else if (el.target.value) {
            label.classList.add("bg-accent-green20");
            labelInner.textContent = fileName;
          }
        } else {
          label.classList.remove("bg-accent-green20");
          labelInner.textContent = "Choose File";
        }
      });
    });
  };

  const sendForm = () => {
    saveTracking();
    let formdata = new FormData();

    // add basic info
    const first_name = document.getElementById("name").value;
    const last_name = document.getElementById("surname").value;
    const email = document.getElementById("email").value;
    const linkedin = document.getElementById("linkedin").value;
    const cv = document.getElementById("cv").files[0];
    const cover_letter = document.getElementById("cover_letter").files[0];

    const errors = validateInput(first_name, last_name, email);
    const applicant_data = {
      "57588b958985f10010fe5e14": first_name,
      "57588b9e8985f10010fe5e15": last_name,
      "57588bac8985f1000e166a34": email,
      "5bced2650e7e5e000c66feb7": linkedin,
      utm_source: localStorage.getItem("utm_source") || "",
      utm_medium: localStorage.getItem("utm_medium") || "",
      utm_term: localStorage.getItem("utm_term") || "",
      utm_content: localStorage.getItem("utm_content") || "",
      utm_campaign: localStorage.getItem("utm_campaign") || "",
    };
    const campaign_number = localStorage.getItem("utm_campaign") || "";

    formdata.append("applicant_data", JSON.stringify(applicant_data));

    if (campaign_number) {
      formdata.append("campaign_number", campaign_number);
    }

    // add files
    formdata.append("57588c328985f1000e166a35", cv);
    formdata.append("60fad65ce6a82d48a0f93928", cover_letter);

    applyMain.disabled = true;
    if (errors) {
      console.log(errors);
      errorForm(errors, applicationContent);
      applyMain.disabled = false;
    } else {
      fetch(`${BASE_URL}/roles/${ROLE_ID}`, {
        method: "POST",
        body: formdata,
      })
        .then(handleErrors)
        .then((data) => {
          successForm(applicationContent);
        })
        .catch((error) => {
          errorForm(error, applicationContent);
        });
    }
  };
  uploadHelper();
  applicationContent.querySelectorAll("input.required").forEach((inputEl) => {
    inputEl.addEventListener("keyup", validateForm);
  });

  applicationContent
    .querySelectorAll('.styled-checkbox, input[name="source"], .input-file')
    .forEach((inputEl) => {
      inputEl.addEventListener("change", validateForm);
    });
  applyMain.addEventListener("click", sendForm);
};
