import { gsap } from "gsap";
import { handleErrors } from "./application-helper";

export function handleUpload(customDate) {
  if (window.location.href.startsWith("https://www.productflows.io")) {
    console.log("Redirecting.. ");
    window.location.replace(
      window.location.href.replace(
        "https://www.productflows.io",
        "https://productflows.io"
      )
    );
  }

  const date = customDate || new Date();

  const lowerDate = new Date("Oct 1 2021 18:00:00 GMT+0100");
  const upperDate = new Date("Oct 4 2021 23:00:00 GMT+0100");
  const startWrapper = document.querySelector(".start-wrapper");
  const successWrapper = document.querySelector(".success-wrapper");
  const errorWrapper = document.querySelector(".error-wrapper");
  const earlyWrapper = document.querySelector(".early-wrapper");
  const lateWrapper = document.querySelector(".late-wrapper");
  const dropArea = document.querySelector(".drop-area");
  const uploadButton = document.getElementById("fileElem");
  const urlParams = new URLSearchParams(window.location.search);
  const applicationId = urlParams.get("applicationId");
  const token = urlParams.get("token");

  if (date >= lowerDate && date <= upperDate) {
    if (!applicationId || !token) {
      throw `applicationId or token can't be nullable (${applicationId}, ${token})`;
    }

    const preventDefaults = (e) => {
      e.preventDefault();
      e.stopPropagation();
    };

    const highlight = (e) => {
      dropArea.classList.add("highlight");
    };

    const unhighlight = (e) => {
      dropArea.classList.remove("highlight");
    };

    const handleDrop = (e) => {
      const dt = e.dataTransfer;
      const file = dt.files[0];

      previewFile(file);
    };

    const handleClick = (el) => {
      const file = el.target.files[0];
      previewFile(file);
    };

    const previewFile = (file) => {
      const fileSize = file.size / 1024 / 1024;
      const fileName = file.name;
      const fileExt =
        fileName.substring(fileName.lastIndexOf(".") + 1, fileName.length) ||
        fileName;
      dropArea.classList.add("highlight");
      dropArea.querySelector(
        ".card__content"
      ).textContent = `Uploading ${fileName}`;
      if (fileSize > 10) {
        alert("File size exceeds 10 MB");
        uploadButton.value = "";
      } else if (fileExt != "pdf") {
        alert("Please use a PDF file");
        uploadButton.value = "";
      } else if (file) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
          uploadFile(file);
        };
      }
    };

    const staggerAnimation = (secondWrapper) => {
      const tl = gsap.timeline();

      tl.to(startWrapper.querySelectorAll(".card"), {
        opacity: 0,
        y: -30,
        ease: "power1.inOut",
        duration: 0.6,
        stagger: 0.2,
        onComplete: function () {
          startWrapper.classList.add("hidden");
          secondWrapper.classList.remove("hidden");
          secondWrapper.scrollIntoView();
        },
      });
      tl.fromTo(
        secondWrapper.querySelectorAll(".card"),
        {
          opacity: 0,
          y: 40,
        },
        {
          opacity: 1,
          y: 0,
          ease: "power1.inOut",
          duration: 0.6,
          stagger: 0.2,
          delay: 0.2,
        }
      );
    };

    const successAnimation = () => {
      staggerAnimation(successWrapper);
    };

    const errorAnimation = (error) => {
      console.log(error);
      staggerAnimation(errorWrapper);
    };

    const uploadFile = (file) => {
      var url = `https://rolemodel.bendingspoons.com/events/${applicationId}/presign`;
      fetch(url, {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: token,
        }),
      })
        .then((response) => {
          return response.json();
        })
        .then((response) => {
          const uploadUrl = response.url;
          const formData = new FormData();
          formData.append("file", file);

          fetch(uploadUrl, {
            method: "put",
            body: formData,
          })
            .then(handleErrors)
            .then((data) => {
              successAnimation();
            })
            .catch((error) => {
              errorAnimation(error);
            });
        })
        .catch((error) => {
          console.log(`error:${error}`);
          console.error(error);
        });
    };

    // Prevent default drag behaviors
    ["dragenter", "dragover", "dragleave", "drop"].forEach((eventName) => {
      dropArea.addEventListener(eventName, preventDefaults, false);
      document.body.addEventListener(eventName, preventDefaults, false);
    });

    // Highlight drop area when item is dragged over it
    ["dragenter", "dragover"].forEach((eventName) => {
      dropArea.addEventListener(eventName, highlight, false);
    });
    ["dragleave", "drop"].forEach((eventName) => {
      dropArea.addEventListener(eventName, unhighlight, false);
    });

    // Handle dropped files
    dropArea.addEventListener("drop", handleDrop, false);
    uploadButton.addEventListener("change", handleClick, false);
  } else {
    startWrapper.classList.add("hidden");
    if (date < lowerDate) {
      earlyWrapper.classList.remove("hidden");
    } else {
      lateWrapper.classList.remove("hidden");
    }
  }
}
