import "./styles/index.css";
import EmblaCarousel from "embla-carousel";
// import { handleCookies } from "./scripts/cookies-helper";
import { cssVarSupport } from "./scripts/browser-helper";
import { handleModal } from "./scripts/modal-helper";
import { handleApplication } from "./scripts/application-helper";
import { handleUpload } from "./scripts/upload-helper";
import {
  handleAccordion,
  createPreloader,
  rollingAnimation,
  handReveal,
} from "./scripts/animation-helper";

const isUpload = window.location.href.indexOf("upload");

cssVarSupport();
document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
  anchor.addEventListener("click", function (e) {
    e.preventDefault();

    document.querySelector(this.getAttribute("href")).scrollIntoView({
      behavior: "smooth",
    });
  });
});

createPreloader();
if (isUpload > -1) {
  handleUpload();
} else {
  document.addEventListener("DOMContentLoaded", () => {
    const emblaNode = document.getElementById("embla");
    const options = { loop: true };
    const embla = EmblaCarousel(emblaNode, {
      dragFree: true,
      containScroll: "keepSnaps",
    });
    handleApplication();
    handleAccordion();
    // handleCookies();
    handleModal();
    handReveal();
    rollingAnimation();

    setTimeout(() => {
      embla.reInit(options);
    }, 1000);
  });
}
window.onbeforeunload = () => {
  window.scrollTo(0, 0);
};
