import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import lottie from "lottie-web";
import * as animationData from "../assets/videos/animation.json";

gsap.registerPlugin(ScrollTrigger);

export const handleAccordion = () => {
  const acc = document.querySelectorAll(".accordion");

  acc.forEach((el) => {
    el.addEventListener("click", () => {
      el.parentElement.classList.toggle("active");
      let panel = el.nextElementSibling;
      if (panel.style.maxHeight) {
        panel.style.maxHeight = null;
        panel.style.opacity = "0";
      } else {
        panel.style.maxHeight = panel.scrollHeight + "px";
        panel.style.opacity = "1";
      }
    });
  });
};

export const rollingAnimation = () => {
  const rolling = document.querySelectorAll(".rotating-text__svg");

  rolling.forEach((el) => {
    gsap.from(el, {
      rotate: 360,
      duration: 0.6,
      ease: "linear",
      scrollTrigger: {
        scrub: 1,
        trigger: el,
        end: "+=400 end",
      },
    });
  });
};

export const lineReveal = () => {
  const bigLetters = document.querySelectorAll(".line-reveal");

  bigLetters.forEach((el) => {
    const lines = el.querySelectorAll(".line span");
    let tl = gsap.timeline({
      scrollTrigger: {
        trigger: el,
        start: "top 70%",
      },
    });
    tl.set(bigLetters, { autoAlpha: 1 });
    tl.from(lines, {
      yPercent: 100,
      duration: 0.5,
      ease: "power2.out",
      stagger: 0.1,
      delay: 0.2,
    });
  });
};

export const imageBlockReveal = () => {
  const animatedImage = document.querySelectorAll(".image-container");

  animatedImage.forEach((el) => {
    const image = el.querySelector("img");
    const revealer = el.querySelector(".revealer");

    let tl = gsap.timeline({
      scrollTrigger: {
        trigger: el,
        start: "top 70%",
      },
    });

    tl.to(revealer, {
      duration: 0.8,
      width: "0%",
      ease: "power2.inOut",
    }).from(image, {
      duration: 0.8,
      scale: 1.3,
      ease: "power2.inOut",
      delay: -1,
    });
  });
};

export const handReveal = () => {
  const hand = document.getElementById("hand");

  gsap.from(hand, {
    translateX: 300,
    translateY: 400,
    rotate: 30,
    duration: 0.4,
    scrollTrigger: {
      trigger: hand,
      scrub: 1,
      start: "-300px 100%",
      end: "-100px 90%",
    },
  });
};

export function createPreloader() {
  document.documentElement.style.overflowY = "hidden";
  const preloader = document.querySelector(".preloader");
  const preloaderLogo = document.querySelector(".preloader__logo");
  const tl = gsap.timeline();
  const text = document.querySelectorAll(".hero-reveal");
  const lines = document.querySelectorAll(".hero-reveal .line span");
  lottie.loadAnimation({
    container: preloaderLogo, // the dom element that will contain the animation
    renderer: "svg",
    autoplay: true,
    loop: false,
    animationData: animationData, // the path to the animation json
  });
  tl.to(preloaderLogo, {
    translateY: "-200%",
    duration: 1.2,
    autoAlpha: 0,
    delay: 0.8,
    ease: "power4.inOut",
  });
  tl.to(
    preloader,
    {
      translateY: "-100%",
      duration: 1,
      ease: "power4.inOut",
      onComplete: () => {
        document.documentElement.style.overflowY = "scroll";
        preloader.classList.add("hidden");
      },
    },
    "-=1"
  );
  tl.set(text, { autoAlpha: 1 });
  tl.from(
    lines,
    {
      yPercent: 100,
      duration: 0.5,
      ease: "power2.out",
      stagger: 0.1,
      delay: 0.2,
    },
    "-=0.6"
  );
}
